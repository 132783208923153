import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header1 from "./Header1/Header1";
import Header2 from "./Header2/Header2";
import Part1 from "./Part1/Part1";
import Part2 from "./Part2/Part2";
import Part3 from "./Part3/Part3";
import Part4 from "./Part4/Part4";
import { useState } from "react";

const Home = () => {
  const [showState, setShowState] = useState(false);
  const [identify, setIdentify] = useState(0);
  return (
    <div className="bg-[#8c411c] w-full h-full z-0">
      <div >
        <Header1/>
      </div>
      <div  className="mt-2">
        <Header2 />
      </div>
      <div  className="-translate-y-10 pr-5 pl-5 " >
        <Part1 num="1" showState={showState} setShowState={setShowState} identify={identify} setIdentify={setIdentify} />
      </div>
      <div className="-translate-y-6 pl-5 pr-5" >
        <Part2 num="2" showState={showState} setShowState={setShowState} identify={identify} setIdentify={setIdentify}/>
      </div>
      <div className=" -translate-y-2 pl-5 pr-5" >
        <Part3  num="3" showState={showState} setShowState={setShowState} identify={identify} setIdentify={setIdentify}/>
      </div>
      <div className="mt-2 pl-5 pr-5" >
        <Part4 num="4" showState={showState} setShowState={setShowState} identify={identify} setIdentify={setIdentify} /> 
      </div>
      <div className="w-[10%] ">
        <div className="  rounded-2xl flex items-center justify-center shadow-lg shadow-slate-900  underline p-1  cursor-pointer hover:bg-[#e0cfb3] hover:no-underline  bg-[#8c411c] ">
          <a
            href="http://www.frontrange4x4.com/forums/newreply.php?p=10669&amp;noquote=1"
            className="pl-2 pr-2 hover:text-black text-[14px] text-white"
          >
            + Replay to Thread
          </a>
        </div>
      </div>
      <div className="mt-8">
        <Footer />
      </div>
    </div>
  );
};
export default Home;
