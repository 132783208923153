import "./App.css";
import Router from "./components/Router";
import { Provider } from "jotai";
// const socket = socketIO.connect("http://192.168.144.123:4000");
function App() {
  const userInfo = {
    name: "Johnny",
    email: "codrkai@gamil.com",
    loggedIn: true,
    cartItems: 4,
  };

  return (
    <Provider>
      <Router />
    </Provider>
  );
}

export default App;
