import { Link } from "react-router-dom";
export default () => {
  return (
    <div className="w-full h-full bg-[#c4a169]">
      <div className="pl-5 pr-5 pt-2 flex flex-col">
        {/* iconBar */}
        <div className="flex flex-row items-center">
          <a href="http://www.frontrange4x4.com/forums/index.php">
            <img
              src={require("../../img/navbit-home.png")}
              className="size-3 mr-1"
            />
          </a>
          <div className="w-full text-[13px] flex flex-wrap justify-start ">
            <a
              href="http://www.frontrange4x4.com/forums/forum.php"
              className="mr-8 rounded-md hover:bg-[#e0cfb3]"
            >
              Forum
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/forumdisplay.php?6-Tech-Topics"
              className="mr-8 rounded-md hover:bg-[#e0cfb3]"
            >
              Tech Topics
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/forumdisplay.php?48-General-4x4-Tech-Topics"
              className="mr-8 rounded-md hover:bg-[#e0cfb3]"
            >
              General 4x4 Tech Topics
            </a>
            <p>
              Is USS AUTOMOTIVE LLC A Secure and Profitable Company ? -4runner
              freak
            </p>
          </div>
        </div>
        {/* end of  iconBar */}
        {/* yellow */}
        <div className="mt-2 pl-3 pr-3">
          <div className="w-full  border-[1px] border-b-[8px] border-[#8c411c] bg-[#F7FE2E]">
            <p className="p-1 text-[13px]">
              Welcome to Front Range 4x4! Please feel free to visit our forum as
              a guest, the entire form is available for your viewing. To become
              a member,
              <a
                href="http://www.frontrange4x4.com/forums/sendmessage.php"
                className="underline font-bold hover:text-[#8c411c]"
              >
                {" "}
                Click Here{" "}
              </a>
              and request membership.
            </p>
          </div>
        </div>
        {/*end of  yellow */}
        {/* button */}
        <div className=" mt-4 flex flex-row  relative">
          <div className="  rounded-2xl flex items-center justify-center shadow-lg shadow-slate-900  underline p-1  cursor-pointer hover:bg-[#e0cfb3] hover:no-underline  bg-[#8c411c] ">
            <a
              href="http://www.frontrange4x4.com/forums/newreply.php?p=10669&amp;noquote=1"
              className="pl-2 pr-2 hover:text-black text-[14px] text-white"
            >
              + Replay to Thread
            </a>
          </div>
          <div className=" flex items-center absolute right-0">
            <p className="text-[13px]">Results 1 to 3 of 3</p>
          </div>
        </div>
        {/* end of button */}
        <div className="w-[80%] mt-2 mb-8 ">
          <a
            href="#"
            className="font-bold underline text-[14px] cursor-pointer hover:text-[#8c411c]"
          >
            Is USS AUTOMOTIVE LLC A Secure and Profitable Company ?
          </a>
        </div>
      </div>
    </div>
  );
};
