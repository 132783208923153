import { Link } from "react-router-dom";
import { counterAtom } from "../JotaiStore/Jotai";
import { useAtom } from "jotai";

export default () => {
  const [num] = useAtom(counterAtom);
  return (
    <>
      <div className="w-full h-[200px] bg-slate-300">
        <div>
          <button> 111 </button>{" "}
        </div>{" "}
        <div>
          <p> {num} </p>{" "}
        </div>{" "}
        <div>
          <Link to="/gru"> fjkwejf </Link>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
};
