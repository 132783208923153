
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Work from "./Work/Work";
import Work1 from "./Work/Work1";

export default function Router() {
 

  const BrowserRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/gru" element={<Work />} />
            <Route path="/gru1" element={<Work1 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  };

  return <BrowserRoutes />;
}