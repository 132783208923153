import { useState } from "react";
export default ({showState,setShowState,identify,setIdentify,num}) => {
  const [view, setView] = useState(false);
  return (
    <div className="w-full ">
      <div className="w-full h-[10px] bg-[#8c411c] border-[1px] border-black"></div>
      {/* textbody1 */}
      <div className="bg-[#e0cfb3] h-full">
        {/* div 1 */}
        <div className=" flex justify-between pr-3 pl-3 border-b-[1px] border-black pb-8">
          {/* picture&text */}
          <div className="flex justify-start p-2">
            {/* picture */}
            <div className="">
              <a href="http://www.frontrange4x4.com/forums/member.php?96-Nay">
                {" "}
                <img src={require("../../img/image_002.jpg")} />
              </a>
            </div>
            {/* end of picture */}
            {/* text */}
            <div className="pl-3 font-bold text-[13px] leading-none">
              <div className="flex items-center relative">
                <img
                  src={require("../../img/user-offline.png")}
                  className="size-4 mr-2"
                />
                <a  className="underline"onClick={() => {
                    setShowState(!showState);
                    setIdentify(3);
                }}
                onMouseEnter={() => { if (showState) setShowState(true); setIdentify(3);} }
               
                >
                  Nay
                </a>
                {/* modal */}
                <div className="absolute translate-y-5 left-4" style={{visibility:showState? (identify
                  == 3 ?"":"hidden"):"hidden"}}>
                  <div className="w-[250px] bg-[#e0cfb3] flex justify-between p-1 border-[1px] rounded-md text-[13px] shadow-lg shadow-gray-700 border-black">
                    <a href="http://www.frontrange4x4.com/forums/member.php?96-Nay">
                      {" "}
                      <div className="flex">
                        <img src={require("../../img/profile.png")} />
                        <p className="font-normal hover:underline">
                          View Profile
                        </p>
                      </div>
                    </a>
                    <a href="http://www.frontrange4x4.com/forums/search.php?do=finduser&userid=96&contenttype=vBForum_Post&showposts=1">
                      {" "}
                      <div className="flex mr-3">
                        <img src={require("../../img/forum.png")} />
                        <p className="font-normal hover:underline">
                          View Forum Posts
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                {/* end of modal */}
              </div>
              <p>500+ Post Club</p>
              <p>Last Online: 5 Hours Ago @ 11:53 AM</p>
            </div>
            {/* end of text */}
          </div>
          {/* end of picture&text */}
          {/* text */}
          <div className="text-[12px] leading-none text-right">
            <a
              href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/&p=10669.html#post10669"
              className="underline hover:text-[#8c411c]"
            >
              #3
            </a>
            <p>Join Date: Mar 2008</p>
            <p>Location: Monument</p>
            <p>Total Posts: 687</p>
            <p>Real Name: Dave Nay</p>
            <a
              href="http://www.frontrange4x4.com/forums/garage.php?do=user_garage_view&id=96"
              className="underline hover:text-[#8c411c]"
            >
              <p>1995 FZJ80</p>
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/album.php?u=96"
              className="underline hover:text-[#8c411c]"
            >
              <p>Nay's Albums</p>
            </a>
            <p>Thanks Given :0</p>
            <p>Thanked 0 Times in 0 Posts</p>
          </div>
          {/* end of text */}
        </div>
        {/* end of div 1 */}
        {/* div2 */}
        <div className="">
          <div className=" flex justify-center ">
            <div className="w-[98%] border-b-[1px] border-black text-[14px] pb-6 ">
              <p>
                They are a serious and experienced shipping company. My son used
                them, he purchased a Yamaha Yzf R1 online and he was very
                satisfied with them but I think this questions was already
                answered by Brody wasn't it? :)
              </p>
            </div>
          </div>
          {/* div3 */}
          <div className="mt-3">
            <div className="pl-4 text-[14px] pt-1">
              <p>
                1995 FZJ80 - 37" Trxus MT - 5.29's - Custom SROR Tube Bumpers -
                De-plasticized - Dented - Dieted - Durabak'd
              </p>
            </div>
            <div className="mt-12 flex justify-start text-[13px] pb-4 font-semibold underline">
              <a
                href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/#top"
                className="underline hover:text-[#8c411c]"
              >
                _Top
              </a>
            </div>
          </div>
          {/* end of div3 */}
        </div>
        {/* end of div2 */}
      </div>
      {/* end of textbody1 */}
    </div>
  );
};
