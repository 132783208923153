import { Link } from "react-router-dom";

export default () => {
  return (
    <div className="w-full">
      <div className="h-[35px] flex justify-center items-center bg-[#c4a169]">
        <div className="flex text-[14px]">
          <a href="http://www.frontrange4x4.com/forums/showthread.php?t=2663&goto=nextoldest" className="underline hover:text-[#8c411c]"><p>Previous Thread </p></a> 
          <p className="ml-1 mr-1">l</p>
          <a href="http://www.frontrange4x4.com/forums/showthread.php?t=2663&goto=nextnewest" className="underline hover:text-[#8c411c]"><p>Next Thread </p></a> 
        </div>
      </div>
      <div className="h-[20px] bg-[#8c411c] border-b-[1px]  border-black"></div>
          <div className="h-[30px] bg-[#8c411c] border-b-[2px] border-black  shadow-sm shadow-black flex justify-end ">
              <div className="flex text-white underline ml-2 text-[14px]">
                  <a href="http://www.frontrange4x4.com/forums/sendmessage.php" className="mr-2"><p>Contact Us</p></a> 
                  <a href="http://www.frontrange4x4.com/forums/rules.php" className="mr-2"><p>Rules & Agreements </p></a> 
                  <a href="http://www.frontrange4x4.com/" className="mr-2"><p>Front Range 4x4 Home </p></a> 
                  <a href="http://www.frontrange4x4.com/forums/archive/index.php" className="mr-2"><p>Archive  </p></a> 
                  <a href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/#top" className="mr-2"><p>Top of Page</p></a> 
              </div>
          </div>
          <div className="mt-16">
              <div className="flex">
                  <p className="mr-3 text-white text-[14px]" >This Page Loaded in 0.24023 seconds </p>
                  <p className="mr-3 text-white text-[14px]" >Memory: 4,640 KB </p>
                  <p className="mr-3 text-white text-[14px]" >Queries: 22 </p>
                  <p className="mr-3 text-white text-[14px]" > Templates: 41</p>
              </div>
          </div>
    </div>
  );
};
