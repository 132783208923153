import { useState } from "react";
import { Link } from "react-router-dom";
export default ({showState,setShowState,identify,setIdentify,num}) => {
  const [view, setView] = useState(false);
  return (
    <div className="w-full ">
      <div className="w-full h-[10px] bg-[#8c411c] border-[1px] border-black"></div>
      {/* textbody1 */}
      <div className="bg-[#e0cfb3] h-full">
        {/* div 1 */}
        <div className=" flex justify-between pr-3 pl-3 border-b-[1px] border-black">
          {/* picture&text */}
          <div className="flex justify-start p-2">
            {/* picture */}
            <div className="">
             <a href="http://www.frontrange4x4.com/forums/member.php?103-Brody" ><img src={require("../../img/image.jpg")} /></a>
            </div>
            {/* end of picture */}
            {/* text */}
            <div className="pl-3 font-bold text-[13px] leading-none">
              <div className="flex items-center relative">
                <img
                  src={require("../../img/user-offline.png")}
                  className="size-4 mr-2"
                />
                <a className="underline " onClick={() => {
                  setShowState(!showState); 
                  setIdentify(2);
                  
                }}
                onMouseEnter={() => { if (showState) setShowState(true); setIdentify(2);} }
    
                >Brody</a>
                 {/* modal */}
                 <div className="absolute translate-y-5 left-4" style={{visibility:showState?( identify == num ?"":"hidden"):"hidden"}}>
                  <div className="w-[250px] bg-[#e0cfb3] flex justify-between p-1 border-[1px] rounded-md text-[13px] shadow-lg shadow-gray-700 border-black">
                    <a href="http://www.frontrange4x4.com/forums/member.php?103-Brody">
                      {" "}
                      <div className="flex">
                        <img src={require("../../img/profile.png")} />
                        <p className="font-normal hover:underline">
                          View Profile
                        </p>
                      </div>
                    </a>
                    <a href="http://www.frontrange4x4.com/forums/search.php?do=finduser&userid=103&contenttype=vBForum_Post&showposts=1">
                      {" "}
                      <div className="flex mr-3">
                        <img src={require("../../img/forum.png")} />
                        <p className="font-normal hover:underline">
                          View Forum Posts
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                {/* end of modal */}
              </div>
              <div>
                <img src={require("../../img/Mr-Sen.gif")} />
              </div>
              <p>Super Mod</p>
              <p>Last Online: 8 Hours Ago @ 08:58 AM</p>
            </div>
            {/* end of text */}
          </div>
          {/* end of picture&text */}
          {/* text */}
          <div className="text-[12px] leading-none text-right">
          <a
              href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/&p=10645.html#post10645"
              className="underline hover:text-[#8c411c]"
            >
              #2
            </a>
            <p>Join Date: Mar 2008 </p>
            <p>Location: Arvada </p>
            <p>Total Posts: 14,360</p>
            <p>Real Name: Pete Brody</p>
            <a href="http://www.frontrange4x4.com/forums/garage.php?do=user_garage_view&id=103" className="underline hover:text-[#8c411c]"><p>94 Yota Pick up</p></a >
            <a href="http://www.frontrange4x4.com/forums/album.php?u=103" className="underline hover:text-[#8c411c]">Brody's Albums</a >
            <p>Thanks Given :47</p>
            <p>Thanked 303 Times in 230 Posts</p>
          </div>
          {/* end of text */}
        </div>
        {/* end of div 1 */}
        {/* div2 */}
        <div className="">
          <div className=" flex justify-center ">
            <div className="w-[98%] border-b-[1px] border-black text-[14px] leading-nomal pb-5 ">
              <p>
                They are something like an escrow service+shipping+logistics.
                They are an excellent time saver. I recommend them. I used USS AUTOMOTIVE LLC 5 months ago selling my car and I love the way
                they move and handle a transactions. They ship fast and they got
                the payment to me immediately after the buyer made the selling
                papers. The buyer was also satisfied.
              </p>
            </div>
          </div>
          {/* div3 */}
          <div className="mt-3">
            <div className="pl-4 ">
              <p className="text-[11px]">
                Anyway, no drug, not even alcohol, causes the fundamental ills
                of society. If we're looking for the source of our troubles, we
                shouldn't test people for drugs, we should test them for
                stupidity, ignorance, greed and love of power.
              </p>
              <a href="http://www.brainyquote.com/quotes/quotes/p/pjorour106010.html" className="text-[blue] font-semibold underline ">
                P. J. O'Rourke
              </a >
              <p className="pt-12 text-[14px] mb-5">
                Stock 1994 Toyota Photochopped to look cool....
              </p>
            </div>
            <div className="flex justify-start pb-3">
              <a href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/#top" className="text-[12px] font-semibold underline hover:text-[#8c411c]">_Top</a >
            </div>
          </div>
          {/* end of div3 */}
        </div>
        {/* end of div2 */}
      </div>
      {/* end of textbody1 */}
    </div>
  );
};
