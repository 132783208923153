
import NavBtn from "./NavBtn.js";
import { generalModalList } from "./data.js";
import { trailModalList } from "./data.js";
import { techModalList } from "./data.js";
import { vendersModalList } from "./data.js";
import { moreModalList } from "./data.js";
import { useEffect, useState } from "react";

export default ({ select, setSelect }) => {
  const [text, setText] = useState("");
  const [navChecked, setNavChecked] = useState(false);
  return (
    <div className="w-full h-[100%]" >
      {/* header&pic */}
      <div className="flex justify-between">
        <div>
          <a href="http://www.frontrange4x4.com/forums/forum.php">
            <img src={require("../../img/Front_Range_4x4_Logo.png")} />
          </a>
        </div>
        {/* login */}
        <div className=" relative">
          <div className="flex flex-row w-[220px] bg-[#c4a169] rounded-bl-md rounded-br-md absolute top-0 right-[100px]">
            <div className="flex justify-around pl-2 pb-1 relative ">
              <div className="mr-1 ">
                <input
                  placeholder="User Name"
                  className="w-20 h-4 text-[12px] focus:outline-none"
                ></input>
              </div>
              <div className="mr-1 ">
                <input className="w-20 h-4 text-[12px] focus:outline-none"></input>
              </div>
              <div>
                <div className=" h-4 border-black border-[1px] bg-white mt-1 flex items-center">
                  <p className="text-[12px]">Log in</p>
                </div>
              </div>
              {/*checkbox  */}
              <div className=" flex items-center absolute translate-y-7 left-4">
                <input type="checkbox" className="mr-2" />
                <p className="text-[12px] text-white">Remember Me?</p>
              </div>
              {/*end of checkbox  */}
            </div>
          </div>
          {/* help */}
          <div className="w-[50px] bg-[#c4a169] rounded-bl-md rounded-br-md absolute top-0 right-[40px]">
            <a
              href="http://www.frontrange4x4.com/forums/faq.php"
              className="text-[13px] flex justify-center p-1 font-bold text-white"
            >
              Help
            </a>
          </div>
          {/* end of help */}
        </div>
        {/* end of login */}
      </div>
      {/* end of  header&pic */}
      {/* tag */}
      <div className="mt-2 w-full">
        <div className="w-full h-[30px] flex justify-between bg-[#c4a169]  border-b-black border-b-[1px]  relative">
          <div className="flex flex-row w-[300px] sm:w-[800px] ">
            {/* IconBar1 */}
            <div className=" w-6 flex justify-center items-center border-solid border-[1px] border-r-black border-b-black bg-[#c4a169]">
              <a></a>
            </div>
            {/* NavBtn*/}
            <div className=" w-[12%]  flex justify-center items-center border-solid border-[1px] border-r-black border-b-black bg-[#c4a169] hover:bg-[#8c411c]">
              <a
                href="http://www.frontrange4x4.com/forums/garage.php"
                className="font-bold text-[13px]"
              >
                Garage
              </a>
            </div>
            <div className="w-[12%]  flex justify-center items-center border-solid border-[1px] border-r-black border-b-black bg-[#c4a169] hover:bg-[#8c411c]">
              <a
                href="http://www.frontrange4x4.com/forums/search.php?do=getdaily&contenttype=vBForum_Post"
                className="font-bold text-[13px]"
              >
                New
              </a>
            </div>
            <NavBtn
              settext={setText}
              text={text}
              setNavChecked={setNavChecked}
              navChecked = {navChecked}
              title="General"
              list={generalModalList}
            />
            <NavBtn
              settext={setText}
              text={text}
              setNavChecked={setNavChecked}
              navChecked = {navChecked}
              title="Trail Forums"
              list={trailModalList}
            />
            <NavBtn
              settext={setText}
              text={text}
              setNavChecked={setNavChecked}
              navChecked = {navChecked}
              title="Tech"
              list={techModalList}
            />
            <NavBtn
              settext={setText}
              text={text}
              setNavChecked={setNavChecked}
              navChecked = {navChecked}
              title="Vendors"
              list={vendersModalList}
            />
            <NavBtn
              settext={setText}
              text={text}
              setNavChecked={setNavChecked}
              navChecked = {navChecked}
              title="More+"
              list={moreModalList}
            />
            {/*end of  NavBtn */}
          </div>
          <div className="flex justify-end ">
            {/* input */}
            <div className="  flex justify-center items-center border-solid border-[1px]  border-r-black border-b-black border-l-black bg-[#c4a169]">
              <input className="h-4 w-[85%] border-[1px] border-black focus:outline-none"></input>
            </div>
            {/* end of  input */}
            {/* Picture */}
            <div className=" w-8 flex justify-center items-center border-solid border-[1px] border-r-black border-b-black  bg-[#c4a169]">
              <a href="">
                <img src={require("../../img/profile.png")} />
              </a>
            </div>
            {/* Picture */}
          </div>
        </div>
        <div className="w-full h-[30px] pl-8 bg-[#c4a169]  rounded-bl-lg rounded-br-lg flex justify-between items-center ">
          <div>
            <a
              href="http://www.frontrange4x4.com/forums/search.php?do=getdaily&amp;contenttype=vBForum_Post"
              className="mr-3 text-[12px]"
            >
              Today's Posts
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/faq.php"
              className="mr-3 text-[12px]"
            >
              FAQ
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/calsearch.php"
              className="mr-3 text-[12px]"
            >
              Calendar
            </a>
            <a href="javascript://" className="mr-3 text-[12px]">
              Community
            </a>
            <a href="javascript://" className="mr-3 text-[12px]">
              Forum Actionsp
            </a>
            <a href="javascript://" className="mr-3 text-[12px]">
              Quick Links
            </a>
          </div>
          <div className="mr-4">
            <a
              href="http://www.frontrange4x4.com/forums/search.php"
              className="text-[12px] hover:underline"
            >
              Advanced Search
            </a>
          </div>
        </div>
      </div>
      {/* end of tag */}
    </div>
  );
};
