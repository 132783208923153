import { useState } from "react";

export default ({ showState, setShowState, identify, setIdentify, num }) => {
  const [rightBtn, setRightBtn] = useState(false);
  const [showNum, setShowNum] = useState(0);
  const [view, setView] = useState(false);

  return (
    <div className="w-full ">
      <div className="flex justify-end relative">
        <a
          href="javascript://"
          className=" pr-3 text-[12px]"
          onClick={() => {
            if (showState == true) setShowState(false);
            setRightBtn(!rightBtn);
            setShowNum(1);
          }}
          onMouseEnter={() => {
            if (rightBtn && showState == false) setRightBtn(true);
            setShowNum(1);
          }}
        >
          Thread ToKHT
        </a>
        <div
          className="absolute translate-y-6 -translate-x-10"
          style={{
            visibility: rightBtn ? (showNum == 1 ? "" : "hidden") : "hidden",
          }}
        >
          <ul className="w-[180px] bg-[#e0cfb3] border-[1px] border-black text-[12px] p-1 ">
            <a href="" className="hover:underline">
              <li>Show Printable Version</li>
            </a>
            <a href="" className="hover:underline">
              <li>Email this Page</li>
            </a>
            <a href="" className="hover:underline">
              <li>Subscribe to this Thread</li>
            </a>
          </ul>
        </div>
        <a
          href="javascript://"
          className=" pr-3 text-[12px]"
          onClick={() => {
            if (showState == true) setShowState(false);
            setRightBtn(!rightBtn);
            setShowNum(2);
          }}
          onMouseEnter={() => {
            if (rightBtn && showState == false) setRightBtn(true);
            setShowNum(2);
          }}
        >
          Search Thread
        </a>
        <div
          className="absolute translate-y-6 right-0"
          style={{
            visibility: rightBtn ? (showNum == 2 ? "" : "hidden") : "hidden",
          }}
        >
          <div className="w-[250px]  bg-[#e0cfb3] border-[1px] border-black p-1">
            <div className="flex justify-around ">
              <input placeholder="Search..." className="h-4 w-[70%]"></input>
              <div className="border-[1px] border-black h-4 flex items-center p-1">
                <a href="" className="text-[12px]">
                  Search
                </a>
              </div>
            </div>
            <div className="flex justify-end text-[13px]">
              <a
                href="http://www.frontrange4x4.com/forums/search.php?search_type=1&amp;searchthreadid=2663&amp;contenttype=vBForum_Post"
                className="underline"
              >
                Advanced Search
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[10px] bg-[#8c411c] border-[1px] border-black"></div>
      {/* textbody1 */}
      <div className="bg-[#e0cfb3] h-full ">
        {/* div 1 */}
        <div className=" flex justify-between pr-3 pl-3 pb-4 border-b-[1px] border-black">
          {/* picture&text */}
          <div className="flex justify-start p-2 font-bold">
            {/* picture */}
            <div>
              <a href="http://www.frontrange4x4.com/forums/member.php?116-4runner-freak">
                <img src={require("../../img/image_003.jpg")} />
              </a>
            </div>
            {/* end of picture */}
            {/* text */}
            <div className="pl-3 leading-none ">
              <div className="flex items-center relative ">
                <img
                  src={require("../../img/user-offline.png")}
                  className="size-4 mr-2"
                />
                <a
                  className="text-[12px] underline"
                  onClick={() => {
                    setShowState(!showState);
                    setIdentify(1);
                  }}
                  onMouseEnter={() => {
                    if (showState) setShowState(true);
                    setIdentify(1);
                  }}
                >
                  4runner freak
                </a>
                {/* modal */}
                <div
                  className="absolute translate-y-5 left-4"
                  style={{
                    visibility: showState
                      ? identify == num
                        ? ""
                        : "hidden"
                      : "hidden",
                  }}
                >
                  <div className="w-[250px] bg-[#e0cfb3] flex justify-between p-1 border-[1px] rounded-md text-[13px] shadow-lg shadow-gray-700 border-black">
                    <a href="http://www.frontrange4x4.com/forums/member.php?116-4runner-freak">
                      {" "}
                      <div className="flex">
                        <img src={require("../../img/profile.png")} />
                        <p className="font-normal hover:underline">
                          View Profile
                        </p>
                      </div>
                    </a>
                    <a href="http://www.frontrange4x4.com/forums/search.php?do=finduser&userid=116&contenttype=vBForum_Post&showposts=1">
                      {" "}
                      <div className="flex mr-3">
                        <img src={require("../../img/forum.png")} />
                        <p className="font-normal hover:underline">
                          View Forum Posts
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                {/* end of modal */}
              </div>
              <p className="text-[12px]">500+ Post Club</p>
              <p className="text-[12px]">Last Online: 5 Hours Ago @ 11:53 AM</p>
            </div>
            {/* end of text */}
          </div>
          {/* end of picture&text */}
          {/* text */}
          <div className=" text-[12px] leading-none text-right ">
            <a
              href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/&p=10636#post10636"
              className="underline hover:text-[#8c411c]"
            >
              #1
            </a>
            <p>Join Date: Mar 2008 </p>
            <p>Location: Northglenn</p>
            <p>Total Posts: 624</p>
            <p>Real Name: Danny</p>
            <a
              href="http://www.frontrange4x4.com/forums/garage.php?do=user_garage_view&id=116"
              className="underline hover:text-[#8c411c]"
            >
              <p>'85 Toyota 4runner - Speed Racer</p>{" "}
            </a>
            <a
              href="http://www.frontrange4x4.com/forums/album.php?u=116"
              className="underline hover:text-[#8c411c]"
            >
              4runner freak's Albums{" "}
            </a>
            <p>Thanks Given :7 </p>
            <p>Thanked 6 Times in 4 Posts </p>
          </div>
          {/* end of text */}
        </div>
        {/* end of div 1 */}
        {/* div2 */}
        <div>
          <div className=" flex justify-center ">
            <div className=" w-[98%] border-b-[1px] border-black text-[14px] ">
              <p className="">
                Where I can find some reviews about USS AUTOMOTIVE LLC? I want
                to buy a 1956 Ford F-100 through their system and I need to find
                more about them....the seller told me that they are an well
                known company. So long story short....How Safe is
                <a
                  href="https://ussautobrokers.com/"
                  className="underline hover:text-[#8c411c]"
                >
                  {" "}
                  ussautobrokers.com?
                </a>{" "}
                Thanks
              </p>
              <p className="mt-2 mb-8">
                4runner freak Last edited by 4runner freak; at 01:52 PM.
              </p>
            </div>
          </div>
          {/* div3 */}
          <div className="mt-3  text-[14px] ">
            <div className="text-center">
              <p>'85 4runner, finally lifted!</p>
              <a
                href="http://www.pirate4x4.com/forum/showthread.php?t=548270"
                className="underline hover:text-[#8c411c]"
              >
                <p>Custom Steering Arms!</p>
              </a>
              <a
                href="http://www.pirate4x4.com/forum/showthread.php?t=862345"
                className="underline hover:text-[#8c411c]"
              >
                Working on DUAL-TRANNYS
              </a>
            </div>
            <div className="flex justify-start">
              <a
                href="http://frontrange4x4-php-quick-links-showthread-forum-14564.mywebcommunity.org/#top"
                className="pl-2 mb-4 font-semibold underline  hover:text-[#8c411c]"
              >
                _Top
              </a>
            </div>
          </div>
          {/* end of div3 */}
        </div>
        {/* end of div2 */}
      </div>
      {/* end of textbody1 */}
    </div>
  );
};
