import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const NavBtn = ({ list, title, text, settext, navChecked, setNavChecked }) => {
  const [isVisible, setVisible] = useState(false);
  const openList = () => {
    settext(title)
    setNavChecked(!navChecked);
  };

  return (
    <div
      className="w-[12%] pr-1 pl-1 flex justify-center items-center border-solid border-[1px] border-r-black border-b-black bg-[#c4a169] relative"
      onClick={openList}
      onMouseEnter={() => { if (navChecked) settext(title); setVisible(true)}}
      onMouseLeave={() => setVisible(false)}
    >
      <a href="javascript://" className="font-bold text-[13px]">
        {title}
      </a>
      <ul
        className="w-[200px] absolute left-0 top-0 translate-y-7 text-[13px] divide-y-2 divide-[#c4a169] border-[4px] border-[#c4a169] bg-[#e0cfb3] z-10"
        style={{ visibility: navChecked && isVisible ?( text === title ? "visible" : "hidden"):"hidden"}}
      >
        {list.map((item) => {
          return (
            <>
              <li className="p-1 hover:underline">
                <a href={item.link}>{item.title}</a>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default NavBtn;
