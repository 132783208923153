const generalModalList = [
  {
    title: "introductions",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?58-Introductions",
  },
  {
    title: "General Topics",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?45-General-Topics",
  },
  {
    title: "Member 4x4 Photo Gallery",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?5-Member-4x4-Photo-Gallery",
  },
  {
    title: "Videos and Photos of Interest",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?35-4x4-Videos-amp-Photos-of-Interest",
  },
  {
    title: "From Help/News",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?56-Forum-Help-News",
  },
  {
    title: "Newbies-Start Here!",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?179-Newbie",
  },
  {
    title: " ATVs and UTVs",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?187-ATVs-and-UTVs",
  },
];
const trailModalList = [
  {
    title: "Trail Run Planning",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?91-Trail-Run-Planning",
  },
  {
    title: "Planned Trail Runs",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?14-Planned-Trail-Runs",
  },
  {
    title: "Trail Run Reports",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?15-Trail-Run-Reports",
  },
  {
    title: "Trail Resources",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?25-Trail-Resources",
  },
  {
    title: "Trail Carnage",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?34-Carnage",
  },
  {
    title: "Trail Directory",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?172-Trail-Directory",
  },
];
const techModalList = [
  {
    title: "General 4x4 Topics",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?48-General-4x4-Tech-Topics",
  },
  {
    title: "Tech How-To",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?12-Tech-How-To-Help-Wanted",
  },
  {
    title: "Tech Help Wanted",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?185-Tech-Help-Wanted",
  },
  {
    title: "Build Threads",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?17-Build-Threads",
  },
  {
    title: "Tech Links",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?50-Tech-Links",
  },
  {
    title: "Make and Model Links",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?39-Make-amp-Model-Links",
  },
];
const vendersModalList = [
  {
    title: "Vendors",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?116-Vendors",
  },
  
];
const moreModalList = [
  {
    title: "Member Skills",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?82-Member-Skills",
  },
  {
    title: "For Sale",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?7-For-Sale",
  },
  {
    title: "Wanted",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?8-Wanted",
  },
  {
    title: "Cool Finds",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?9-Cool-Finds",
  },
  {
    title: "Free/Trade",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?10-Free-Trade",
  },
  {
    title: "Chat",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?47-Chat",
  },
  {
    title: "Employment",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?103-Employment",
  },
  {
    title: "Camping and Expeditions",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?180-Camping-and-Expeditions",
  },
  {
    title: "Hiking,Biking,Boarding,Sking,etc",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?97-Hiking,%20Biking,%20Boarding,%20Skiing,%20etc",
  },
  {
    title: "Motorcycles",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?96-Motorcycles",
  },
  {
    title: "Geek Street",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?112-Geek-Street",
  },
  {
    title: "Car Chat",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?182-Car-Chat",
  },
  {
    title: "Shooters Corner",
    link: "http://www.frontrange4x4.com/forums/forumdisplay.php?188-Shooters-Corner",
  },
];

const navBtnData = {
  title: ["General", "Trail Forums", "Tech", "Vendors", "More+"],
  list:[generalModalList, techModalList,trailModalList,vendersModalList ,moreModalList ]
}
export { generalModalList };
export { techModalList };
export { trailModalList };
export { vendersModalList };
export { moreModalList };