"use client";
import { counterAtom } from "../JotaiStore/Jotai";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
export default () => {
  const [num, setNum] = useAtom(counterAtom);
  const [counter] = useAtom(counterAtom);
  return (
    <>
      <div className="w-full h-[200px] bg-slate-300">
        <div>
          <button onClick={() => setNum((e) => e + 1)}> 111 </button>{" "}
        </div>{" "}
        <div>
          <p> {num} </p>{" "}
        </div>{" "}
        <p> {counter} </p> <Link to="/gru1"> thflef </Link>{" "}
      </div>{" "}
    </>
  );
};
