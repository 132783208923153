import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/font-awesome/css/font-awesome.css";
import "./output.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
  // document.getElementById("root")
);
